<template>
  <div class="ciamLogin">
    <div class="os-form__subtitle">Login or sign up</div>

    <OsButton class="ciamLogin__button" is-primary @click="onLogin">
      <p>
        View all your searches, enquiries and orders on any device by either
        logging in or signing up. You can login using your existing MercedesMe
        account.
      </p>
      <div class="ciamLogin__button__icons flex">
        <span>Login / Sign up</span>
        <OsIcon name="Arrow" fill="#FFF" width="16" />
      </div>
    </OsButton>
  </div>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'

export default {
  name: 'FormsSectionLoginCard',
  props: {
    formType: {
      type: String as PropType<EFormType>,
      required: true
    }
  },
  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const { onLogin: ciamLogin } = useCiamLogin()

    const onLogin = () => {
      ciamLogin()
      $dataLayer.linkClick({
        category: `form-step-1-${props.formType}`,
        action: 'login'
      })
    }

    return { onLogin }
  }
}
</script>

<style lang="scss" scoped>
.ciamLogin {
  position: relative;
  .ciamLogin__button {
    border-radius: 8px;
    background-color: $blue;
    color: $white;
    height: auto;
    padding: rem(16);
    display: flex;
    flex-direction: column;
    p {
      font-size: rem(16);
      text-align: left;
      padding-bottom: rem(16);
      white-space: normal;
    }
    .ciamLogin__button__icons {
      margin-left: auto;
      justify-content: flex-end;
      svg {
        transform: rotate(-90deg);
        margin-left: rem(0.8);
        align-self: center;
      }
    }
    .ciamLogin__intro__ctas {
      display: flex;
      padding: rem(24) 0 0 rem(24);
      justify-content: flex-end;
      .os-button svg {
        transform: rotate(-90deg);
        margin-left: rem(0.8);
      }
    }
  }
}
</style>
